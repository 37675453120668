.dashboard-box {
  height: 200px;
  text-align: center;
  cursor: pointer;
  color: white !important;
}

.orders {
  background-color: #a86729 !important;
}

.products {
  background-color: rgb(46 71 122) !important;
}

.sales {
  background-color: rgb(28, 122, 51) !important;
}

.users {
  background-color: rgb(16 44 197) !important;
}

.box-list {
  font-weight: 400;
  margin: 0.25rem 0;
}
